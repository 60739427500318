<template>
  <div class="page overflow-hidden" id="page1">

            <div class="front-page">
                <div class="brand">
                   <div class="my-text">
                    <p id="year">2024</p>
                    <h1 id="name-tag" > &nbsp; HEY GUYS</h1>
                    <p>The "Hey Guys" platform is designed to help families or friends collaborate on joint projects by creating squads. 
                        This platform allows members to contribute financially, assign contributions and targets, set up budgets and expenditure limits, and track progress within a secure and user-friendly interface.</p>
                    <p>Many families and friends face difficulties in managing joint projects that involve shared financial contributions and coordination. "Hey Guys" addresses this by providing an organized, transparent, and collaborative platform to handle such projects.</p>
                   </div>
                </div>

                <div class="bottom-nav">
                    <div class="links">    
                        <span class="n"><router-link to="/">WHY</router-link></span>
                        <span class="n"><router-link to="/">HOW IT WORKS</router-link></span>
                        <span class="n"><router-link to="/">ABOUT</router-link></span>
                        <span class="n"><router-link to="/">FAQ</router-link></span>
                        <span class="n"><router-link to="/">CONTACT</router-link></span>
                        <span class="n"><router-link to="/">PRESS</router-link></span>
                    </div>
                    <label style="font-size: 1.3em; font-weight: 900;"  class="next" for="checkbox-page1">&rsaquo;</label>
                </div>
            </div>
            <div class="back-page">
                <img src="https://images.unsplash.com/photo-1582213782179-e0d53f98f2ca?q=80&w=2940&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D">
                <label class="prev" for="checkbox-page1">Previous<i class="fas fa-chevron-left"></i></label>
            </div>
        </div>
</template>

<script>
export default {

}
</script>

<style>

</style>
<template>
  <div class="page overflow-hidden" id="page3">
            <div class="front-page">
                <div class="loging-in-page container">
                 <div class="small-talk">
                    <h2>Get Started</h2>
                    <hr />
                    <br/>
                    <p>Ready to start this journey with us?</p>
                    <br/>
                 </div>
                 <div class="tnc">
                    <p>By proceeding, you agree to the terms & conditions.</p>
                    <div class="signup-options">
                        <h6>SIGN UP</h6>
                        <a href="/sign-up">
                            <button type="button"><email-icon title="this is an icon!" fillColor="#4285F4" :size="22" /> Sign up With Email</button>
                        </a>
                        <a href="/sign-up">
                            <button type="button"><google-icon title="this is an icon!" fillColor="#FF0000" :size="22" /> Sign up With Google</button>
                        </a>
                    </div>
                    <br/>
                    <div class="strike">
                        <span>OR</span>
                    </div>
                    <div class="sign-in-options">
                        <h6>SIGN-IN</h6>
                        <a href="/login">
                            <button type="button"><email-icon title="this is an icon!" fillColor="#34A853" :size="22" /> Sign In With Email</button>
                        </a>
                        <a href="/login">
                            <button type="button"><google-icon title="this is an icon!" fillColor="#FBBC05" :size="22" /> Sign In With Google</button>
                        </a>
                    </div>

                    <br/>
                 </div>
                 <div class="learn-more bottom-div">
                    <b-icon icon="arrow-up"></b-icon>
                    <p>This platform is developed by developers who have committed any extra time they have in a day to give you a cool experience. <a href="http://" target="_blank" rel="noopener noreferrer">Learn More</a> </p>
                 </div>
                </div>
            </div>
        </div>
</template>

<script>
import EmailIcon from 'vue-material-design-icons/Email.vue';
import GoogleIcon from 'vue-material-design-icons/Google.vue';

export default {
    components: {
            EmailIcon,
            GoogleIcon,
    }

}
</script>

<style>

</style>
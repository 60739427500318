<template>
  <router-view />  
</template>

<script>


export default {
  name: 'App',
  components: {
    
  }
}
</script>

<style>

body {
  padding: 0;
  margin: 0;
}

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  /* margin-top: 2px; */
}

.logo {
        width: 11%;
        margin-top: 5%;
    }

    /* .login input, .register input{
        height: 3em;
    }

    .login button, .register button {
        width: 20em;
        height: 3.2em;
        display: block;
        margin-top: 1em;
        margin-left: auto;
        margin-right: auto;
        cursor: pointer;
        color: #03424C;
    } 


    @media (max-width: 768px) {
        .login input, .register input {
            display: block;
            width: 90%;
            margin-bottom: 2em;
            margin-right: auto;
            margin-left: auto;
            padding-left: 1em;
        }

        .login button, .register button{
            width: 22em;
            height: 4em;
            cursor: pointer;
            color: #03424C;
        }
    }
    */
</style>

<template>
    <HeaderPage />
    <h1>CONTRIBUTIONS</h1> 
</template>
<script>
import HeaderPage from './HeaderPage.vue'

    export default {
        name: 'ContributionsPage',
        components: {
            HeaderPage
        },
        mounted() {
            let dude = localStorage.getItem('facts')
            if(!dude) {
                this.$router.push({name:'Login'})
            }
        }
    }
</script>
<template>
    <div class="row">
        <div class="col-2">
            <SideBarPage/>
        </div>
        <div class="col-10">
            <div class="row"><BannerPage /></div>
            <div class="row"><HeaderPage /></div>
            

        </div>
        
    </div>
    
    <!-- <h1>Ahoi, {{ name }}</h1> aye aye if admin -->
</template>

<script>
import HeaderPage from './HeaderPage.vue';
import SideBarPage from './SideBarPage.vue';
import BannerPage from './BannerPage.vue';

    export default {
        name: 'HomePage',
        data(){
            return {
                name: ''
            }
        },
        components: {
            HeaderPage,
            SideBarPage,
            BannerPage  
        },
        mounted() {
            let dude = localStorage.getItem('facts')
            this.name=JSON.parse(localStorage.getItem('streetName'))
            if(!dude) {
                this.$router.push({name:'Login'})
            }
        }
    }
</script>
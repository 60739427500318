<template>
  <div class="c">
    <h1 class="title">Solution + Design</h1>

   <div class="container">
           <section class="inner-container">
                <!--welcome part-->
                <div class="content">
                    <div class="losgo">
                        <h4>✌️ Hey Guys</h4>
                    </div>
                    <div class="coming-soon">
                        <h2 class="title-tag">... COMING SOON</h2>
                            <p>
                            We're building a platform to help manage small projects
                            collaboration and funds by making it eisier to set up a project's
                            squad, invite members to a project, manage contributions while
                            also staying transparent with funds coming in and out of the
                            project's wallet.
                            </p>
                            <p class="wim"><strong>WHAT DOES THIS MEAN FOR YOU?</strong></p> 
                            <ul>
                                <li>
                                    No more gazillion whatsapp groups and project admins dont have
                                    to bother members with yet another new group with longevity as
                                    short as firefly's lifespan just to get collaborate and manage
                                    funds
                                </li>
                                <li>
                                    Easy set up of squad wallet that grants easy collaboration &
                                    transparency managing funds, be it collections or payouts
                                </li>
                                <li>
                                    Succinct access to joint wallet account with totally
                                    customizable configurations
                                </li>
                            </ul>
                            <p>and get to worry about one less thing :)</p>
                            <p><strong>We will be launching early January 2025</strong></p>
                            <div class="contact-us">
                                <p>
                                    <a href="mailto:untitled.bunch@gmail.com"
                                    >To contact us on email</a
                                    >
                                </p>
                            </div>   
                    </div>
                </div>

                <!--join listing part-->
                <div class="join-listing">
                    <h5>
                        Join the waiting list to receive updates on the product development
                        and get the first peek on our platform
                    </h5>
                    <form @submit.prevent="submitUserSuggestion">
                        <div class="form-field">
                        <label>Name</label>
                        <input type="text" name="name" id="name" v-model="name" />
                        </div>

                        <div class="form-field">
                        <label>Email</label>
                        <input type="email" required v-model="email" />
                        </div>

                        <div class="form-field">
                        <label>Which feature are you exited to see?</label>
                        <input type="text" v-model="tempSuggestion" />
                        </div>

                        <div class="submit">
                        <button class="btn btn-sm center rounded-pill" type="submit">SUBMIT</button>
                        </div>
                    </form>
                      
                </div>
            </section>
   </div>
  </div>
</template>

<script>
import axios from "axios";

export default {
  data() {
    return {
      name: "",
      email: "",
      tempSuggestion: "",
    };
  },
  methods: {
    async submitUserSuggestion(event) {
      var data = JSON.stringify({
        subject: "Hey Guys platform MVP tests",
        recipient: [
          {
            name: "Admin: ",
            email: "untitled.bunch@gmail.com",
          },
        ],
        cc: [],
        bcc: [],
        bodyText:
          "waiting list feedback:  \n From: " +
          this.email +
          "\n Feedback" +
          this.tempSuggestion,
        description: "waiting list feedback: ",
        squadron: "608507b6-21f3-4d0c-9609-3f7578c07a65",
        metaData: {},
      });

      var config = {
        method: "post",
        url: `https://hey-guys-latest.onrender.com/KE/messaging/send/delivery-mode/email`,
        headers: {
          "Content-Type": "application/json",
        },
        timeout: 100000,
        data: data,
      };

      axios(config)
        .then(function (response) {
          console.log("Response from site");
          console.log(JSON.stringify(response.data));

          event.target.reset();

          alert("Thank you for joining our waiting list");
        })
        .catch(function (error) {
          console.log("Error" + error);
        });
    },
  },
};
</script>

<style scoped>
@import "./comingsoon.css";
</style>
<template>
    <div class="container login-page" style="margin-top: 3em;">
        <div class="row centered g-0">
            <div class="col">
                <div class="section-a">
                    <div class="row">
                    <h1 id="login-text">LOGIN</h1>
                </div>
                <div class="row">
                    <p id="msg-text">
                        By logging in, you agree to the ridiculously long terms and conditions that you didn't bother to read.
                        <!-- <input type="checkbox" id="checkbox" v-model="checked" />
                        <label id="terms_checkbox" for="checkbox"><span v-bind:class="{dot: checked}"></span>{{ checked }}</label> -->
                    </p>
                </div>
                <div class="row">
                    <p id="foot">✌️ Hey Guys</p>
                </div> 
                </div>
            </div>

            <div class="col">
                <div class="login">
                    <div id="email">
                        <div class="row">
                            <label>Enter Your Email</label>
                        </div>
                        <div class="row">
                            <input type="text"  v-model="email" placeholder="" />
                        </div>
                    </div>

                    <div id="password">
                        <div class="row">
                            <label>Enter Your Password</label>
                        </div>
                        <div class="row">
                            <input type="password" v-model="password"  placeholder="" />
                        </div>
                    </div>

                    <div class="row submit">
                        <div class="col"></div>
                        <div class="col">
                            <button type="button" v-on:click="fetchUserInformation" >SUBMIT</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="popup" >
            <transition name="fade" ref="fade"   >
                <div class="modal-overlay" v-if="showModal" @click="showModal = false">
                    <h4>Aye Aye, <br/> Welcome back Fam!!</h4>
                    <button class="button" @click="showModal = false">
                    LOADING... 🚣
                    </button>
                </div>
            </transition>
            <transition name="slide" >
                <div class="modal" v-if="showModal">
                    <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Voluptatem provident explicabo accusamus laudantium voluptatum nobis sed nesciunt neque possimus molestiae?</p>
                </div>
            </transition>
        </div>

    </div>
    
    <!-- 
    <img class="logo"  src="../assets/heyguys-high-resolution-logo-transparent.png"/>
    <h1>Hold the ropes, Login First Captain</h1>

    <div class="login">
        <input type="text" v-model="email" placeholder="Enter Email" />
        <input type="password" v-model="password"  placeholder="Enter Password" />
        <button type="button" v-on:click="fetchUserInformation" >Connect</button>

        <p>
            <router-link to="/sign-up" >Sign-Up</router-link>
        </p>
    </div>
    -->
</template>

<script>
import axios from 'axios'

    export default {
        name: 'LoginPage',
        data() {
            return {
                email: '',
                password: '',
                showModal: '',
                checked: true
            }
        },

        methods: {
            async login(){
                let result = await axios.get(
                    `http://localhost:3000/user?email=${this.email}&password=${this.password}`
                )
                console.info(result)

                if (result.status == 200 && result.data.length==1) {
                    alert("Aye Aye, Welcome back Fam!!")
                    localStorage.setItem("facts", JSON.stringify(result.data))
                    localStorage.setItem("famzPhone", JSON.stringify(result.data[0].msisdn))
                    localStorage.setItem("streetName", JSON.stringify(result.data[0].streetName))
                    this.$router.push({name:'HomePage'})
                } else {
                    alert("Something is wrong fam, recheck the info ")
                }
            },

            openModal() {
                this.showModal = true
            },

            async fetchUserInformation() {
                
                let url = `https://hey-guys-latest.onrender.com/KE/clan/member/${this.password}`
                console.info("The url is "+url)

                const config = {
                    timeout: 100000,
                    headers: {
                        'Access-Control-Allow-Origin': '*',
                        'Access-Control-Allow-Headers': '*',
                    }
                }
                this.openModal();

                let result = await axios.get(url,config)
                console.log("Fetch User details {}", result.data.object)
                let resData = result.data.object
                this.openModal();
                if(resData != null){
                    let memberId = resData.memberId
                    let msisdn = resData.msisdn
                    let fname = resData.fname
                    let lname = resData.lname
                    let idNumber = resData.idNumber
                    let countryCode = resData.countryCode
                    let squadId = resData.squadrons[0].squadronId
                    
                    
                    localStorage.setItem("facts", JSON.stringify(resData))
                    localStorage.setItem("famzPhone", JSON.stringify(msisdn))
                    localStorage.setItem("streetName", JSON.stringify(fname+" "+lname))
                    localStorage.setItem("memberId", JSON.stringify(memberId))
                    localStorage.setItem("idNumber", JSON.stringify(idNumber))
                    localStorage.setItem("countryCode", JSON.stringify(countryCode))
                    localStorage.setItem("squadId", JSON.stringify(squadId))
                    this.$router.push({name:'HomePage'})

                } else {
                    alert("Something is wrong fam, recheck the info ")
                
                }
            }
        },
        

        mounted() {
            let dude = localStorage.getItem('facts')
            if(dude) {
                this.$router.push({name:'HomePage'})
            }
        }
    }
</script>

<style scoped>
@import "./login.css";  
</style>
<template>
    
<nav>
  <ul>
    <li>
      <div>
        <br/>
        <router-link to="/">Home</router-link>
        <br>
      </div>
    </li>
    <li>
      <div>
        <br>
        <router-link to="/">SQUADS</router-link>
        <br>
      </div>
    </li>
    <li>
      <div>
        <br/>
        <router-link to="/payments">Payments</router-link>
        <br>
      </div>
    </li>
    <li>
      <div>
        <br/>
        <router-link to="/">SAVINGS</router-link>
        <br>
      </div>
    </li>
    <li>
      <div>
        <br/>
        <router-link to="/">CONTRIBUTIONS</router-link>
        <br>
      </div>
    </li>
    <li>
      <div>
        <br/>
        <router-link to="/">NUDGES</router-link>
        <br>
      </div>
    </li>
    <li>
      <div>
        <br/>
        <router-link to="/">NOTIFICATIONS</router-link>
        <br>
      </div>
    </li>
    <li>
      <div>
        <br/>
        <router-link to="/">PROFILE</router-link>
        <br>
      </div>
    </li>
    <li>
      <div>
        <br/>
        <a v-on:click="logout" href="#">Dock</a>
        <br>
      </div>
    </li>
    <!-- <li>
      <div class="about-icon">
        <div class="head">
          <div class="eyes"></div>
          <div class="beard"></div>
        </div>
      </div>
    </li>
    <li>
      <div class="work-icon">
        <div class="paper"></div>
        <div class="lines"></div>
        <div class="lines"></div>
        <div class="lines"></div>
      </div>
    </li>
    <li>
      <div class="mail-icon">
        <div class="mail-base">
          <div class="mail-top"></div>
        </div>
      </div>
    </li> -->
  </ul>
</nav>
</template>

<script>
    export default {
        name: 'SideBarPage',

        methods: {
            logout() {
                localStorage.clear();
                this.$router.push({name: 'Login'})
            }
        }
    }
</script>

<style scoped>
   *,*:before,*:after {
	box-sizing: border-box;
    }

    :after {
        content: "";
    }
    
    ul {
        list-style-type: none;
        background: #03424C;
        color: white;
        font-size: .7em;
        line-height:1.4;
        height: 100vh;
        font-smoothing: anti-aliased;
    }

    nav {
        float: left;
        position: relative;
        top: 0;
        left: 0;
        background: transparent;
        overflow: hidden;
    }

    nav a {
        text-decoration: none;
        color: #f2f2f2;
    }

    nav ul {
        text-align: center;
    }

    nav ul li {
        position: relative;
        width: 12em;
        cursor: pointer;
        background: #03424C;
        text-transform: uppercase;
        transition:all .4s ease-out;
    }

    nav ul li:after {
        position: absolute;
        background: white;
        color: #03424C;
        top:0;
        left: 3em;
        width: 16em; 
        height: 100%;
        opacity:.5;
        transform: perspective(400px) rotateY(90deg);
        transform-origin: 0 100%;
        transition:all .4s ease-out;
    }

    nav ul li:nth-child(1):after { 
        content: "HOME";
        line-height: 8em;
    }
    nav ul li:nth-child(2):after { 
        content: "SQUADS";
        line-height: 8em;
    }
    nav ul li:nth-child(3):after { 
        content: "PAYMENTS";
        line-height: 8em;
    }
    nav ul li:nth-child(4):after { 
        content: "Savings";
        line-height: 8em;
    }
    nav ul li:nth-child(5):after { 
        content: "Contributions";
        line-height: 8em;
    }
    nav ul li:nth-child(6):after { 
        content: "Nudges";
        line-height: 8em;
    }
    nav ul li:nth-child(7):after { 
        content: "Notifications";
        line-height: 8em;
    }
    nav ul li:nth-child(8):after { 
        content: "Profile";
        line-height: 8em;
    }
    nav ul li:nth-child(9):after { 
        content: "Dock";
        line-height: 8em;
    }

    nav ul li:hover {
        transform: translateX(-6.5em);
    }

    nav ul li:hover:after {
    opacity: 1;
        transform: perspective(400px) rotateY(0deg) scale(1) ;
    }


    nav ul li > div {
        display: inline-block;
        padding: 2em 0;
        background: transparent;
    }

    nav ul li div { position: relative; }

.roof {
	width: 0;
	height: 0;
	top:2px;
	border-style: solid;
	border-width: 0 21px 15px 21px;
	border-color: transparent transparent #ffffff transparent;
}

.roof-edge {
	position: absolute;
	z-index: 20;
	left: -17px;
	top: 3px;
	width: 0;
	height: 0;
	border-style: solid;
	border-width: 0 17px 12px 17px;
	border-color: transparent transparent #03424C transparent;
}

/*white triangle over red triangle*/
.roof-edge:after {
	position: absolute;
	left: -14.5px;
	top: 3px;
	width: 0;
	height: 0;
	border-style: solid;
	border-width: 0 14.5px 10px 14.5px;
	border-color: transparent transparent white transparent;
}

.front {
	position: relative;
	top: 3px;
	width: 28.5px;
	height: 20px;
	margin: 0 auto;
	background: white;
}
/*door*/
.front:after {
	position: absolute;
	background: #03424C;
	width: 11px;
	height: 13px;
	bottom: 0;
	left:9px;
}

/*/// About me ////*/

.head {
	width: 32px;
	height: 35px;
	background: white;
	border-radius:8px;
}
/*mouth*/
.head:after {
	width: 4px;
	height: 10px;
	background: white;
	position: absolute;
	border-radius:4px 0 0 4px;
	top:21px;
	left: 14px;
	transform:rotate(270deg);
}

.eyes {
	width: 8px;
	height: 5px;
	border-radius: 50%;
	position: absolute;
	top: 10px;
	left: 5px;
	background: #03424C;
}
/*right eye*/
.eyes:after {
	width: 8px;
	height: 5px;
	border-radius: 50%;
	position: absolute;
	top: 0;
	left: 14px;
	background: #03424C;
}

.beard {
	width: 32px;
	height: 17px;
	background: #03424C;
	border:2px solid white;
	position: absolute;
	bottom: 0;
	left: 0;
	border-radius:0 0 8px 8px;
}
/*nose*/
.beard:after {
	position: absolute;
	top:-2px;
	left: 11px;
	background: white;
	width:6px;
	height: 4px;
	border-left:1px solid #03424C;
	border-right:1px solid #03424C;
}

/*//work//*/

.paper {
	position: relative;
	height:32px;
	width: 29px;
	background: white;
	border:2px solid white;
}

/*window*/
.paper:after {
	position: absolute;
	top:1px;
	left: 0;
	width: 25px;
	height: 29px;
	background: white;
	border-top:4px solid #03424C;
}

.lines {
	position: absolute;
	top: 36px;
	left: 5px;
	width: 11px;
	box-shadow: 0 0 0 1px #03424C;
}

.lines:after {
	position: absolute;
	top: 4px;
	left: 3px;
	width: 14px;
	box-shadow: 0 0 0 1px #03424C;
}

.lines:nth-child(2) {
	position: absolute;
	top: 44px;
	left: 8px;
	width: 11px;
}

.lines:nth-child(2):after {
	position: absolute;
	top: 4px;
	left: -3px;
	width: 11px;
}

.lines:nth-child(3) {
	position: absolute;
	top: 52px;
	left: 8px;
	width: 14px;
}

.lines:nth-child(3):after {
	display: none;
}

/*//mail //*/

.mail-base {
	position: relative;
	width: 32px;
	height: 18px;
	background: white;
}

.mail-top {
	position: absolute;
	z-index: 20;
	left: 0;
	top: 0;
	width: 0;
	height: 0;
	transform: rotate(180deg);
	border-style: solid;
	border-width: 0 16px 11px 16px;
	border-color: transparent transparent #03424C transparent;
}

.mail-top:after {
	position: absolute;
	z-index: 20;
	left: -16px;
	top: 3px;
	width: 0;
	height: 0;
	border-style: solid;
	border-width: 0 16px 9px 16px;
	border-color: transparent transparent white transparent;
}


  

</style>
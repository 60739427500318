
<template>
    <video autoplay loop class="video" >
        <source src="../assets/kids_playing.mp4" type="video/webm" />
        <source src="../assets/kids_playing.mp4" type="video/mp4" />
    </video>
    <input type="checkbox" name="" id="checkbox-cover">
    <input type="checkbox" name="" id="checkbox-page1">
    <input type="checkbox" name="" id="checkbox-page2">
    <input type="checkbox" name="" id="checkbox-page3">
    <div class="book" >
        <div class="cover">
            <label for="checkbox-cover">
            </label>
        <h1 style="color: white;">Hey Guys</h1>
        <div class="menu-icon-container pull-right">
            <label for="checkbox-cover">
                <menu-icon title="this is an icon!" fillColor="#FFFFFF" :size="40" /> 
            </label>          
        </div>
        </div>

            <div class="page overflow-hidden" id="page1">
            <div class="front-page">
                <div class="brand">
                   <div class="my-text">
                    <p id="year">2024</p>
                    <h1 id="name-tag" > &nbsp; HEY GUYS</h1>
                    <p>Hey Guys... Get it, greeting is also the platform 🙃. </p>
                    <p>The "Hey Guys" platform is designed to help families or friends collaborate on joint projects by creating squads. 
                        This platform allows members to contribute financially, assign contributions and targets, set up budgets and expenditure limits, and track progress within a secure and user-friendly interface.</p>
                    <p>Many families and friends face difficulties in managing joint projects that involve shared financial contributions and coordination. "Hey Guys" addresses this by providing an organized, transparent, and collaborative platform to handle such projects.</p>
                   </div>
                </div>
                <div class="bottom-nav">
                    <div class="links">
                        
                    <span class="n"><router-link to="/">WHY</router-link></span>
                    <span class="n"><router-link to="/">HOW IT WORKS</router-link></span>
                    <span class="n"><router-link to="/">ABOUT</router-link></span>
                    <span class="n"><router-link to="/">FAQ</router-link></span>
                    <span class="n"><router-link to="/">CONTACT</router-link></span>
                    <span class="n"><router-link to="/">PRESS</router-link></span>
                    
                    </div>
                    <label style="font-size: 1.3em; font-weight: 900;"  class="next" for="checkbox-page1">&rsaquo;</label>
                </div>
            </div>
            <div class="back-page">
                <img src="https://images.unsplash.com/photo-1582213782179-e0d53f98f2ca?q=80&w=2940&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D">
                <label class="prev" for="checkbox-page1">Prev<i class="fas fa-chevron-left"></i></label>
            </div>
        </div>
        <div class="page overflow-hidden" id="page2">
            <div class="front-page">
                <br/>
                <h2>Get to know us</h2>
                <br/>
                <div class="row gtku-item ms-3  first" style="border-top: 1em;">
                    <div class="col">
                        <a href=""><p>Our why</p></a>
                    </div>
                    <div class="col"> <a href=""><span class="arrow-right">→ </span></a></div>
                </div>
                <div class="row gtku-item ms-3 ">
                    <div class="col">
                        <a href=""><p>How  it Works</p></a>
                    </div>
                    <div class="col"> <a href=""><span class="arrow-right">→ </span></a></div>
                </div>
                <div class="row gtku-item ms-3 ">
                    <div class="col">
                        <a href=""><p>About us</p></a>
                    </div>
                    <div class="col"> <a href=""><span class="arrow-right">→ </span></a></div>
                </div>
                <div class="row gtku-item ms-3 ">
                    <div class="col">
                        <a href=""><p>Blog posts</p></a>
                    </div>
                    <div class="col"> <a href=""><span class="arrow-right">→ </span></a></div>
                </div>
                <div class="row gtku-item ms-3 ">
                    <div class="col">
                        <a href=""><p>Join the Team</p></a>
                    </div>
                    <div class="col"> <a href=""><span class="arrow-right">→ </span></a></div>
                </div>
                <div class="row gtku-item ms-3 ">
                    <div class="col">
                        <a href=""><p>Big announcements</p></a>
                    </div>
                    <div class="col"> <a href=""><span class="arrow-right">→ </span></a></div>
                </div>
                <div class="row gtku-item ms-3 ">
                    <div class="col">
                        <a href=""><p>Frequently asked Questions</p></a>
                    </div>
                    <div class="col"> <a href=""><span class="arrow-right">→ </span></a></div>
                </div>
                <div class="row gtku-item ms-3 "></div>

                <div class="row contacts">
                    <div class="col">
                        <span>
                            <a href="/sign-up">
                            <email-icon title="Shoot us an email" fillColor="#34A853" :size="22" /> 
                        </a>
                        </span>
                    </div>
                    <div class="col">
                        <span>
                            <a href="/sign-up">
                            <instagram-icon title="DM us on IG" fillColor="#34A853" :size="22" /> 
                        </a>
                        </span>
                    </div>
                    <div class="col">
                        <span>
                            <a href="/sign-up">
                            <x-icon title="Give us shoutout at X" fillColor="#34A853" :size="22" /> 
                        </a>
                        </span>
                    </div>
                    <div class="col">
                        <span>
                            <a href="/sign-up">
                            <facebook-icon title="Tag us on facebook" fillColor="#34A853" :size="22" /> 
                        </a>
                        </span>
                    </div>
                </div>
                
                
                
                <label class="next" for="checkbox-page2">Next<i class="fas fa-chevron-right"></i></label>
            </div>
            <div class="back-page">
                <img src="https://images.unsplash.com/photo-1496516348160-24b35a31856f?q=80&w=2787&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D">
                <label class="prev" for="checkbox-page2">Prev<i class="fas fa-chevron-left"></i></label>
            </div>
        </div>
        <div class="page overflow-hidden" id="page3">
            <div class="front-page">
                <div class="loging-in-page container">
                 <div class="small-talk">
                    <h2>Get Started</h2>
                    <hr />
                    <p>Ready to start this journey with us?</p>
                    <br/>
                 </div>
                 <div class="tnc">
                    <p>By proceeding, you agree to the terms & conditions.</p>
                    <div class="signup-options">
                        <h6>SIGN UP</h6>
                        <a href="/sign-up">
                            <button type="button"><email-icon title="this is an icon!" fillColor="#4285F4" :size="22" /> Sign up With Email</button>
                        </a>
                        <a href="/sign-up">
                            <button type="button"><google-icon title="this is an icon!" fillColor="#FF0000" :size="22" /> Sign up With Google</button>
                        </a>
                    </div>
                    <br/>
                    <div class="strike">
                        <span>OR</span>
                    </div>
                    <div class="sign-in-options">
                        <h6>SIGN-IN</h6>
                        <a href="/login">
                            <button type="button"><email-icon title="this is an icon!" fillColor="#34A853" :size="22" /> Sign In With Email</button>
                        </a>
                        <a href="/login">
                            <button type="button"><google-icon title="this is an icon!" fillColor="#FBBC05" :size="22" /> Sign In With Google</button>
                        </a>
                    </div>

                    <br/>
                 </div>
                 <div class="learn-more bottom-div">
                    <b-icon icon="arrow-up"></b-icon>
                    <p>This platform is developed by developers who have committed any extra time they have in a day to give you a cool experience. <a href="http://" target="_blank" rel="noopener noreferrer">Learn More</a> </p>
                 </div>
                </div>
            </div>
        </div>
        
        <div class="back-cover"></div>
    </div>

</template>

<script>
import MenuIcon from 'vue-material-design-icons/Menu.vue';
import EmailIcon from 'vue-material-design-icons/Email.vue';
import GoogleIcon from 'vue-material-design-icons/Google.vue';
import InstagramIcon from 'vue-material-design-icons/Instagram.vue';
import XIcon from 'vue-material-design-icons/Twitter.vue';
import FacebookIcon from 'vue-material-design-icons/Facebook.vue';



// import ArrowRightIcon from 'vue-material-design-icons/ArrowRight.vue';




// import LoginPage from './LoginTwoPage.vue';


    export default {
        name: 'WebsitePage',
        components: {
            MenuIcon,
            EmailIcon,
            GoogleIcon,
            InstagramIcon,
            XIcon,
            FacebookIcon
            // ArrowRightIcon
            // LoginPage
        }
    }
</script>

<style scoped>

.arrow-right {
    font-size: 2em;
}

.gtku-item{
    border-top: .05em;
    border-bottom: .05em;
    border-right: 0;
    border-left: 0;
    border-style: solid;
    vertical-align: middle;
    border-image: linear-gradient(to right, #cdcdcd 90%, transparent 10%) 100% 1;
}

.gtku-item a {
    text-decoration: none;
    color: inherit;
}


.gtku-item p {
    vertical-align: middle;
    text-align: left;
    padding-top: 1em;
}


/* PAGE 3 */
hr {
    border: none;
    padding: 0;
}

.learn-more {
    text-align: center;
}

.strike span {
    font-size: .7em;
    color: #2C2C2C;
}

.strike {
        display: block;
        text-align: center;
        overflow: hidden;
        white-space: nowrap; 
    }

    .strike > span {
        position: relative;
        display: inline-block;
    }
	
    .strike > span:before,
    .strike > span:after {
        content: "";
        position: absolute;
        top: 50%;
        width: 60vw;
        height: 1px;
        background: #bec6d1;
    }

    .strike > span:before {
        right: 100%;
        margin-right: 25%;
    }

    .strike > span:after {
        left: 100%;
        margin-left: 25%;
    }


    

.signup-options button, .sign-in-options button {
    width: 70%;
    margin-top: 2%;
    padding-top: 1%;
    padding-bottom: 1%;
    cursor: pointer;
    border: 1px solid #2C2C2C;
    border-radius: 0.4em;
}

.sign-in-options {
    margin-top: 4%;
}

.container {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: space-between;
            min-height: 98%;
            width: auto;
        }

.bottom-div {
    margin-top: auto;
    font-size: .7em;
}



video{
    z-index: -1000;
    position: absolute;
    object-fit: cover;
    width: 100vw;
    height: 100vh;
    left: 0;
}

.brand {
    background: url("../assets/heyguys-high-resolution-logo-transparent.png") no-repeat center center
    fixed;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  /* opacity: 0.5; */
  height: 100%;
}

.my-text {
    background-color: #ffffff;
  border: .4px solid #063d46;
  opacity: .95;
  height: 100%;

}


input{
    display: none;
}
body{
    background: #94bbe9f8;
    background: linear-gradient(90deg, rgb(240, 248, 255) 0%, rgba(148,187,233,0.9710477941176471) 100%);
    /* background-image: url('../assets/heyguys-high-resolution-logo-transparent.png'); */
    display: flex;
    align-items: center;
    justify-content: center;
}

.book {
    width: 38vw;
    height: 99.5vh;
    position: relative;
    transition: transform 1s;
    perspective: 1500;
    margin-top: .1em;
}

.cover, .back-cover {
    background-color: #03424C;
    width: 100%;
    height: 100%;
    border-radius: 0  5% 5% 0;
    box-shadow: 0 0 1% rgb(240, 248, 255);
    display: flex;
    align-items: center;
    justify-content: center;
}

.cover {
    position: absolute;
    z-index: 4;
    transform-origin: center left;
    transition: transform 1s;
}

.cover label{
    width: 100%;
    height: 100%;
    cursor: pointer;
}

.back-cover {
    position: relative;
    z-index: -1;
}

.page{
    background-color: whitesmoke;
    width: 37vw;
    height: 97vh;
    position: absolute;
    border-radius: 0  5% 5% 0;
    margin-top: 1%;
    transform-origin: left;
    transform-style: preserve-3d;
    transform: rotateY(0deg);
    transition-duration: 1.5s;

}


.page img {
    width: 100%;
    height: 100%;
    border-radius: 0  5% 5% 0;
}

.front-page {
    position: absolute;
    width: 100%;
    height: 100%;
    backface-visibility: hidden;
    box-sizing: border-box;
    padding: 1rem;
}
.back-page {
    transform: rotateY(180deg);
    position: absolute;
    width: 100%;
    height: 100%;
    backface-visibility: hidden;
    z-index: 99;
    transform: rotateY(180deg);
}

.next, .prev,.links {
	position: absolute;
	bottom: 1em;
	cursor: pointer;
}
.next {
	right: 1em;
}
.prev {
	left: 1em;
}
#page1 {
	z-index: 3;
}
#page2 {
	z-index: 2;
}
#page3 {
	z-index: 1;
}
.links {
    padding-left: .5em;
}
.n {
    text-decoration: none;
    color: #063d46;
    padding: 0 .5em;
}

.pr {
    font-size: 4em;
    font-weight: 900;
    bottom: 0;
    top: 0;
    padding-bottom: -1em;
    margin: 0;
}


.loging-in-page {
    overflow: scroll;
    width: 90%;
    margin-left: 1em;
    margin-top: 1em;
}



#checkbox-cover:checked ~ .book  {
    transform: translateX(50%);
}

#checkbox-cover:checked ~ .book .cover {
	transition: transform 1.5s, z-index 0.5s 0.5s;
	transform: rotateY(-180deg);
	z-index: 1;
}
#checkbox-cover:checked ~ .book .page {
	box-shadow: 0 0 3px rgb(99, 98, 98);
}
#checkbox-page1:checked ~ .book #page1 {
	transform: rotateY(-180deg);
	z-index: 2;
}
#checkbox-page2:checked ~ .book #page2 {
	transform: rotateY(-180deg);
	z-index: 3;
}


@include media-breakpoint-down(md) {
    .cover, .back-cover {
    background-color: #000;
  }
 }

</style>














<!-- <template>
    <div class="row">
        
        <div class="menu-icon-container pull-right">
            <menu-icon title="this is an icon!" fillColor="#FFFFFF" :size="41" />            
        </div>

        <div class="brand">
            <p id="year">2024</p>
            <span id="logo"></span>
            <h1 id="name-tag" > &nbsp; HEY GUYS</h1>
        </div>
        
        <div class="login-link"><p>LOGIN</p></div>
    </div>
</template>

<script>
import MenuIcon from 'vue-material-design-icons/Menu.vue';

    export default {
        name: 'WebsitePage',
        components: {
  MenuIcon
}
    }
</script>

<style>
:root {
    --header-bg-color: #333; 
    --header-txt-color: #fff;
}
body{
    background: #94bbe9f8;
    background: linear-gradient(90deg, rgb(240, 248, 255) 0%, rgba(148,187,233,0.9710477941176471) 100%);
}
.menu-icon-container{
    text-align: right;
    background: transparent;
    color: whitesmoke;
    padding-right: 5%;
    padding-top: 2%;
}

#name-tag{
    position: absolute;
    width: 100%;
    font-size: 10vh;
    width: 60vw;
}
</style>

<style>

    #name-tag {
    position: absolute;
    width: 91em;
    height: 10em;
    left: 0px;
    top: .6em;

    font-family: 'Inter';
    font-style: normal;
    font-weight: 800;
    font-size: 6.2em;
    line-height: 1.25em;
    text-align: center;
    letter-spacing: 0.6em;

    color: #0F4A67;

    mix-blend-mode: darken;
    /* Drop Shadow/400 */
    text-shadow: 0px 16px 32px rgba(12, 12, 13, 0.1), 0px 4px 4px rgba(12, 12, 13, 0.05);

    }

    #year{
        /* 2024 */

        position: absolute;
        width: 7em;
        height: 28px;
        left: 14em;
        top: 3.4em;

        font-family: 'Inter';
        font-style: normal;
        font-weight: 510;
        font-size: 1.25em;
        line-height: 1.28em;
        text-align: center;
        letter-spacing: 0.05em;

        color: #FFFFFF;
    }

    #logo {
        /* heyguys-high-resolution-logo-transparent 1 */

        position: absolute;
        width: 9%;
        height: 16%;
        left: 7%;
        top: 9%;
        background: center / contain no-repeat url("../assets/heyguys-high-resolution-logo-transparent.png"),
                    transparent 26% url("../assets/heyguys-high-resolution-logo-transparent.png");

    }

    .login-link {
        /* LOGIN */

        position: absolute;
        left: 40em;
        top: 20em;

        font-family: 'Inter';

font-style: normal;

font-weight: 200;

font-size: 1em;
/* 
line-height: 42px;
text-align: center;
letter-spacing: 0.01em;
/* 
color: #83ACC1; */


    }
    
</style> -->
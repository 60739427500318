<template>
 <div>
    <div class="background-video">
        <video autoplay loop class="video" >
            <source src="../../../assets/kids_playing.mp4" type="video/webm" />
            <source src="../../../assets/kids_playing.mp4" type="video/mp4" />
        </video>
    </div>
    
    <input type="checkbox" name="" id="checkbox-cover" style="display: none;">
    <input type="checkbox" name="" id="checkbox-page1" style="display: none;">
    <input type="checkbox" name="" id="checkbox-page2" style="display: none;">
    <input type="checkbox" name="" id="checkbox-page3" style="display: none;">
    
    <div class="book" >
        <div class="coversection">
            <CoverSection/>
        </div>

        <div>
            <page-one-section/>
            <PageTwoSection/>
            <PageThreeSection/>
        </div>


        
        
        <div class="back-cover"></div>
    </div>
 </div>

</template>


<script>
import CoverSection from './CoverSection.vue';
import PageOneSection from './PageOneSection.vue';
import PageThreeSection from './PageThreeSection.vue';
import PageTwoSection from './PageTwoSection.vue';

export default {

    components: {
        CoverSection,
        PageOneSection,
        PageTwoSection,
        PageThreeSection
    }

}
</script>

<style scoped>
@import "./officialwebsite.css";
</style>
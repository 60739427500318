<template>
    <img class="logo"  src="../assets/heyguys-high-resolution-logo-transparent.png"/>
    <h1>Sign Up(Set up your account creds)</h1>

    <div class="register">
        <input type="text" v-model="name" placeholder="Enter Name" />
        <input type="text" v-model="email" placeholder="Enter Email" />
        <input type="text" v-model="streetName" placeholder="Enter Street Name" />
        <input type="text" v-model="msisdn" placeholder="Enter Mobile Number" />
        <input type="text" v-model="favJam" placeholder="Enter Fav Song" />
        <input type="password" v-model="password"  placeholder="Enter Password" />
        <button type="button" v-on:click="signUp" >Hop In</button>

        <p>
            <router-link to="/login" >Login</router-link>
        </p>
    </div>
</template>

<script>
import axios from 'axios'
    export default {
        name: 'SignUp',
        data() {
            return  {
                name: '',
                email: '',
                streetName: '',
                msisdn: '',
                favJam: '',
                password: ''
            }
        },
        methods: {
            async signUp(){
                let result =  await axios.post("http://localhost:3000/user", {
                    name: this.name,
                    email: this.email,
                    streetName: this.streetName,
                    msisdn: this.msisdn,
                    favJam: this.favJam,
                    password: this.password
                });

                console.log(result)

                if (result.status == 201) {
                    alert("Ahoi, Welcome Fam!!")
                    localStorage.setItem("facts", JSON.stringify(result.data))
                    localStorage.setItem("famzPhone", JSON.stringify(result.data[0].msisdn))
                    localStorage.setItem("streetName", JSON.stringify(result.data[0].streetName))
                    this.$router.push({name:'HomePage'})
                } else {
                    alert("Something is wrong fam, recheck the info ")
                }
            }
        },
        
        mounted() {
            let dude = localStorage.getItem('facts')
            if(dude) {
                this.$router.push({name:'HomePage'})
            }
        }
    }
</script>

<style>

</style>
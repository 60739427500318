<template>
    <div class="cover">
        <label for="checkbox-cover"></label>
        <h1 style="color: white;">Hey Guys</h1>
        <div class="menu-icon-container pull-right">
            <label for="checkbox-cover">
                <menu-icon title="this is an icon!" fillColor="#FFFFFF" :size="40" />
            </label>
        </div>
    </div>
</template>

<script>
import MenuIcon from 'vue-material-design-icons/Menu.vue';

export default {
    components: {
        MenuIcon
    }

}
</script>

<style >
</style>
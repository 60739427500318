<template>
  <div class="page overflow-hidden" id="page2">
            <div class="front-page">
                <br/>
                <h2>Get to know us</h2>
                <br/>
                <div class="row gtku-item ms-3  first" style="border-top: 1em;">
                    <div class="col">
                        <a href=""><p>Our why</p></a>
                    </div>
                    <div class="col"> <a href=""><span class="arrow-right">→ </span></a></div>
                </div>
                <div class="row gtku-item ms-3 ">
                    <div class="col">
                        <a href=""><p>How  it Works</p></a>
                    </div>
                    <div class="col"> <a href=""><span class="arrow-right">→ </span></a></div>
                </div>
                <div class="row gtku-item ms-3 ">
                    <div class="col">
                        <a href=""><p>About us</p></a>
                    </div>
                    <div class="col"> <a href=""><span class="arrow-right">→ </span></a></div>
                </div>
                <div class="row gtku-item ms-3 ">
                    <div class="col">
                        <a href=""><p>Blog posts</p></a>
                    </div>
                    <div class="col"> <a href=""><span class="arrow-right">→ </span></a></div>
                </div>
                <div class="row gtku-item ms-3 ">
                    <div class="col">
                        <a href=""><p>Join the Team</p></a>
                    </div>
                    <div class="col"> <a href=""><span class="arrow-right">→ </span></a></div>
                </div>
                <div class="row gtku-item ms-3 ">
                    <div class="col">
                        <a href=""><p>Big announcements</p></a>
                    </div>
                    <div class="col"> <a href=""><span class="arrow-right">→ </span></a></div>
                </div>
                <div class="row gtku-item ms-3 ">
                    <div class="col">
                        <a href=""><p>Frequently asked Questions</p></a>
                    </div>
                    <div class="col"> <a href=""><span class="arrow-right">→ </span></a></div>
                </div>
                <div class="row gtku-item ms-3 "></div>

                <div class="row contacts">
                    <div class="col">
                        <span>
                            <a href="/sign-up">
                            <email-icon title="Shoot us an email" fillColor="#34A853" :size="22" /> 
                        </a>
                        </span>
                    </div>
                    <div class="col">
                        <span>
                            <a href="/sign-up">
                            <instagram-icon title="DM us on IG" fillColor="#34A853" :size="22" /> 
                        </a>
                        </span>
                    </div>
                    <div class="col">
                        <span>
                            <a href="/sign-up">
                            <x-icon title="Give us shoutout at X" fillColor="#34A853" :size="22" /> 
                        </a>
                        </span>
                    </div>
                    <div class="col">
                        <span>
                            <a href="/sign-up">
                            <facebook-icon title="Tag us on facebook" fillColor="#34A853" :size="22" /> 
                        </a>
                        </span>
                    </div>
                </div>
                
                
                
                <label class="next" for="checkbox-page2">Next<i class="fas fa-chevron-right"></i></label>
            </div>
            <div class="back-page">
                <img src="https://images.unsplash.com/photo-1496516348160-24b35a31856f?q=80&w=2787&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D">
                <label class="prev" for="checkbox-page2">Prev<i class="fas fa-chevron-left"></i></label>
            </div>
        </div>
</template>

<script>
import EmailIcon from 'vue-material-design-icons/Email.vue';
import InstagramIcon from 'vue-material-design-icons/Instagram.vue';
import XIcon from 'vue-material-design-icons/Twitter.vue';
import FacebookIcon from 'vue-material-design-icons/Facebook.vue';

export default {
    components: {
            EmailIcon,
            InstagramIcon,
            XIcon,
            FacebookIcon
        }

}
</script>

<style>

</style>
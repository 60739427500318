<template>
    <HeaderPage />
    <h1>ARGOSY</h1> 

    <br/>

    <h2>SQUAD MEMBERS</h2>
    <table >
        <tr>
            <th>FIRST NAME</th>
            <th>LAST NAME</th>
            <th>ID NUMBER</th>
            <th>PHONE NUMBER</th>
            <th>COUNTRY</th>
        </tr>
        <tr v-for="member in members" :key=member.memberId >
            <td> {{ member.fname }} </td>
            <td> {{ member.lname }} </td>
            <td> {{ member.idNumber }} </td>
            <td> {{ member.msisdn }} </td>
            <td> {{ member.countryCode }} </td>
        </tr>
    </table>
</template>
<script>
import HeaderPage from './HeaderPage.vue'
import axios from 'axios';

    export default {
        name: 'ArgosyPage',
        data(){
            return {
                members:[]
            }
        },
        components: {
            HeaderPage
        },
        async mounted() {
            let dude = localStorage.getItem('facts')
            if(!dude) {
                this.$router.push({name:'Login'})
            }

            let countryCode = JSON.parse(localStorage.getItem("countryCode")) 
            let squadId = JSON.parse(localStorage.getItem("squadId"))
            let res =  await axios.get(`https://hey-guys-latest.onrender.com/${countryCode}/clan/members/squad/${squadId}`)
            console.log(res)
            this.members = res.data.content
        }
    }
</script>

<style scoped>
    table {
        border: 1px solid #03424C;
        width: 100%;
    }

    td, th {
        width: auto;
        height: 2em;
    }
</style>
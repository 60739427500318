<template>
    <div class="nav">
        <router-link to="/">Home</router-link>
        <router-link to="/contributions">Contributions</router-link>
        <router-link to="/payments">Payments</router-link>
        <router-link to="/">Fleet Captains</router-link>
        <router-link to="/argosy">Argosy</router-link>
        <router-link to="/">Resources</router-link>
        <a v-on:click="logout" href="#">Dock</a>
    </div>
</template>

<script>
    export default {
        name: 'HeaderPage',

        methods: {
            logout() {
                localStorage.clear();
                this.$router.push({name: 'Login'})
            }
        }
    }
</script>

<style scoped>
    .nav {
        overflow: hidden;
        background-color: #03424C;
    }
    .nav a {
        float: left;
        color: #f2f2f2;
        padding: 1em;
        text-align: center;
        text-decoration: none;
        margin-right: 1em;
    }

    .nav a:hover {
        /* background-color: #F56607; */
        background-color: #ddd;
        color: #333;
    }
</style>